<template>
  <div id="app" ref="App">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    if (
      sessionStorage.getItem('companyUserList') != 'undefined' &&
      sessionStorage.getItem('companyUserList') != null
    ) {
      this.$store.dispatch(
        'companyUserList',
        JSON.parse(sessionStorage.getItem('companyUserList'))
      )
      this.$store.dispatch(
        'companyList',
        JSON.parse(sessionStorage.getItem('companyList'))
      )
    }
  },
  methods: {},
}
</script>

<style lang="less">
body {
  margin: 0;
  box-sizing: border-box !important;
}
#app {
  height: 100px;
}
div,
p,
span {
  font-size: 12px;
}
p {
  margin: 0;
}
// 表格
.el-table {
  font-size: 12px !important;
}
.el-table .el-table__cell {
  text-align: center !important;
}
.el-table__cell {
  padding: 8px 0 !important;
}

// 布局
.el-main {
  position: relative;
  overflow: hidden !important;
}
/* 输入框 */
.el-input__inner {
  height: 30px !important;
  font-size: 12px !important;
  white-space: nowrap !important;
  line-height: 0 !important;
}

._Inp {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    width: 100px;
    text-align: right;
  }
}

// 下拉框(样式完整)
._Select {
  width: 230px;
  display: flex;
  align-items: center;
  > span {
    width: 100px;
    text-align: right;
  }
}
.el-input__icon {
  line-height: 30px !important;
}
// 按钮
.el-button {
  // 蓝色背景按钮
  font-size: 12px !important;
  border-radius: 2px !important;
  padding: 0px 10px !important;
  height: 30px !important;
  line-height: 30px !important;
  i {
    line-height: 30px !important;
  }
}
._ImgBtn {
  //el-button无背景颜色带边框，并可添加图片
  background-color: #f3f3fb !important;
  border: 1px solid #5a8bff !important;
  color: #5a8bff !important;
  span {
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
}
// 分页
._Pagination {
  position: absolute;
  bottom: 15px;
  right: 20px;
  width: 100%;
  text-align: end;
}
// 公用子页面
._BoxHeader {
  background-color: #4274c0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 20px;
  height: 40px;
}
.PageContain {
  position: absolute;
  width: 100%;
  background-color: white;
  top: 50px;
  bottom: 0;
}
._SelectHeader {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  & .el-button {
    margin-left: 10px;
  }
  > ._Inp {
    margin-top: 0 !important;
  }
  .el-input__inner {
    background-color: #edf0f7 !important;
  }
}
._SelectHeaderColor {
  background-color: #f0f0f0;
  padding-left: 10px;
}
._Title {
  background-color: #edf2fa;
  padding: 8px 12px;
  display: inline-block;
}
._Table {
  padding: 0 10px;
  width: 98%;
}
._TypeBox {
  width: 105px;
  height: 40px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #e7e9ec;
  box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
.el-image-viewer__img {
  width: 50% !important;
  height: auto;
}
.image-slot {
  z-index: 3000 !important;
}
span {
  cursor: pointer;
}
.el-tree-node:focus > .el-tree-node__content {
  background-color: #d4e5f9 !important;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  margin-top: 0;
  color: #333333;
}
.el-tree-node__content {
  height: 32px !important;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.el-tree-node__content:hover {
  background-color: #d4e5f9;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #d4e5f9 !important;
}
</style>
