import { COMPANY_USER_LIST } from './mutation-types'
import { COMPANY_LIST } from './mutation-types'
import { POST_AUD_FUN_STATUS } from './mutation-types'
import { MENU_LIST } from './mutation-types'
import { COMPANY } from './mutation-types'

export default {
  [COMPANY_USER_LIST](state, { data }) {
    state.companyUserList = data
  },
  [COMPANY_LIST](state, { data }) {
    state.companyList = data
  },
  [POST_AUD_FUN_STATUS](state, { data }) {
    state.postAudFunStatus = data
  },
  [MENU_LIST](state, { data }) {
    state.menuList = data
  },
  [COMPANY](state, { data }) {
    state.company = data
  }
}
