import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('../pages/system/Login.vue')
const Home = () => import('../pages/home/Home.vue')
const Page = () => import('../pages/home/Page.vue')

// 系统设置
const FunctionManage = () =>
  import('../pages/system-settings/FunctionManage.vue')
const JobFunMaintain = () =>
  import('../pages/system-settings/JobFunMaintain.vue')
const RolePermissions = () =>
  import('../pages/system-settings/RolePermissions.vue')
const Company_Dept = () => import('../pages/system-settings/Company_dept.vue')
const Post_setting = () => import('../pages/system-settings/Post_setting.vue')
const Person_Setting = () =>
  import('../pages/system-settings/Person_Setting.vue')
const TimeMonitoring = () =>
  import('../pages/system-settings/TimeMonitoring.vue')
const controlApply = () => import('../pages/controlApply/index.vue')
// 基础设置
const RegionManage = () => import('../pages/basic-settings/RegionManage.vue')
const FileClassManage = () =>
  import('../pages/basic-settings/FileClassManage.vue')
const category = () => import('../pages/basic-settings/category.vue')
const assignStudy = () => import('../pages/assignStudy')
const PostFunSet = () => import('../pages/basic-settings/PostFunSet.vue')
const Plate = () => import('../pages/basic-settings/Plate.vue')

// 题库管理
const QuestionPoint = () => import('../pages/other/QuestionPoint.vue')
const QuestionDetail = () => import('../pages/other/QuestionDetail.vue')
const Maintain = () => import('../pages/question-bank/Maintain.vue')
const interim = () => import('../pages/question-bank/interim.vue')
const Import = () => import('../pages/question-bank/import.vue')
const WindControImport = () => import('../pages/question-bank/WindControImport.vue')
const WindControlMaintain = () => import('../pages/question-bank/WindControlMaintain.vue')
const SImport = () => import('../pages/question-bank/7S-import.vue')
const scrutiny = () => import('../pages/question-bank/scrutiny.vue')
const PostFunTale = () => import('../pages/question-bank/PostFunTable.vue')
const Exam = () => import('../pages/question-bank/Exam.vue')
const ThemeImport = () => import('../pages/question-bank/ThemeImport.vue')
const GeneralQuestions = () => import('../pages/question-bank/GeneralQuestions.vue')

// 统计分析
const Statistics = () => import('../components/Statistics.vue')
const DrawQStatist = () => import('../pages/statistics/DrawQStatist.vue')
const Quarter = () => import('../pages/statistics/Quarter.vue')
const Monthly = () => import('../pages/statistics/Monthly.vue')
const QuestionPoints = () => import('../pages/statistics/QuestionPoints.vue')
const AddQuestionRecord = () =>
  import('../pages/statistics/AddQuestionRecord.vue')
const MonitorRecord = () => import('../pages/statistics/MonitorRecord.vue')
const MonitoringIssues = () =>
  import('../pages/statistics/MonitoringIssues.vue')
const AuditorStatistics = () =>
  import('../pages/statistics/AuditorStatistics.vue')
const ExamScoreStatistics = () =>
  import('../pages/statistics/ExamScoreStatistics.vue')
const CompanyDeptAudit = () =>
  import('../pages/statistics/CompanyDeptAudit.vue')
const FileExamClauseStatis = () =>
  import('../pages/statistics/FileExamClauseStatis.vue')
const AuditRecords = () => import('../pages/statistics/AuditRecords.vue')
const AnnualAuditExam = () => import('../pages/statistics/AnnualAuditExam.vue')
const SafetyAssessmentRules = () => import('../pages/statistics/SafetyAssessmentRules.vue')

//检查
const Examine_work = () => import('../pages/Examine/Examine_work.vue')
const Examine_topic = () => import('../pages/Examine/Examine_topic.vue')
const Flies_work = () => import('../pages/Flies/Flies_work.vue')
const Flies_topic = () => import('../pages/Flies/Flies_topic.vue')
const ExamineRules = () => import('../pages/rules/ExamineRules.vue')
const RulesDetail = () => import('../pages/rules/RulesDetail.vue')
const Lean = () => import('../pages/Lean/Lean.vue')
const SpecialSubject = () => import('../pages/Lean/SpecialSubject.vue')
const RiskSubject = () => import('../pages/Lean/RiskSubject.vue')
const AspSubject = () => import('../pages/Lean/AspSubject.vue')
const test = () => import('../pages/Lean/test.vue')
const AssignTest = () => import('../pages/Lean/AssignTest.vue')
const score = () => import('../pages/Lean/score.vue')
const AssignScore = () => import('../pages/Lean/AssignScore.vue')
const ControlPage = () => import('../pages/control/ControlPage.vue')
const ControlDetial = () => import('../pages/control/ControlDetial.vue')
// 审核
const Plan = () => import('../pages/audit/Plan.vue')
const Mixing = () => import('../pages/audit/Mixing.vue')
const MixingDetail = () => import('../pages/audit/MixingDetail.vue')
// 档案管理
const ArchivesManage = () => import('../pages/archives/ArchivesManage.vue')
const AuditChildPage = () => import('../pages/archives/AuditChildPage.vue')
const Risk = () => import('../pages/risk/riskList.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/login',
      name: 'login',
      query: { username: '', userno: '' },
      component: Login,
    },
    {
      path: '/page',
      component: Page,
      meta: {
        keepAlive: false,
      },
    },
    {
      path: '/plan',
      component: Plan,
      meta: {
        keepAlive: false,
      },
    },

    {
      path: '/home',
      component: Home,
      children: [
        {
          path: '/mixing',
          component: Mixing,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/mixing-detail',
          component: MixingDetail,
          name: 'MixingDetail',
          meta: {
            keepAlive: false,
          },
        },
        // 系统设置
        {
          path: '/function-manage',
          component: FunctionManage,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/Company_Dept',
          component: Company_Dept,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/function-manage',
          component: FunctionManage,
          meta: {
            keepAlive: false,
          },
        },

        {
          path: '/function-manage',
          component: FunctionManage,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/job-fun-maintain',
          component: JobFunMaintain,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/role-permissions',
          component: RolePermissions,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/Statistics',
          component: Statistics,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/drawq-statist',
          component: DrawQStatist,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/quarter',
          component: Quarter,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/monthly',
          component: Monthly,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/question-points',
          component: QuestionPoints,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/add-question-record',
          component: AddQuestionRecord,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/monitor-record',
          component: MonitorRecord,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/monitoring-issues',
          component: MonitoringIssues,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/auditor-statistics',
          component: AuditorStatistics,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/exam-score-statistics',
          component: ExamScoreStatistics,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/company-dept-audit',
          component: CompanyDeptAudit,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/fileExam-clause-statis',
          component: FileExamClauseStatis,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/audit-records',
          component: AuditRecords,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/annual-audit-exam',
          component: AnnualAuditExam,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/safety-assessment-rules',
          component: SafetyAssessmentRules,
          meta: {
            keepAlive: false,
          },
        },
        
        // 基础设置
        {
          path: '/region-manage',
          component: RegionManage,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/file-class-manage',
          component: FileClassManage,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/Post_setting',
          component: Post_setting,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/Person_Setting',
          component: Person_Setting,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/time-monitoring',
          component: TimeMonitoring,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/controlApply',
          component: controlApply,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/category',
          component: category,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/assignStudy',
          component: assignStudy,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/post-fun-set',
          component: PostFunSet,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/plate',
          component: Plate,
          meta: {
            keepAlive: false,
          },
        },
        // 题库管理
        {
          path: '/maintain',
          component: Maintain,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/interim',
          component: interim,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/import',
          component: Import,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/wind-control-import',
          component: WindControImport,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/wind-control-maintain',
          component: WindControlMaintain,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/7SImport',
          component: SImport,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/scrutiny',
          component: scrutiny,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/post-fun-table',
          component: PostFunTale,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/exam',
          component: Exam,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/theme-import',
          component: ThemeImport,
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/general-questions',
          component: GeneralQuestions,
          meta: {
            keepAlive: false,
          },
        },
        //Examine
        {
          path: '/Examine_work',
          name: 'Examine_work',
          component: Examine_work,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/Flies_work',
          component: Flies_work,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/Examine_topic',
          component: Examine_topic,
          name: 'Examine_topic',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/Flies_topic',
          component: Flies_topic,
          name: 'Flies_topic',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/ExamineRules',
          component: ExamineRules,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/RulesDetail',
          component: RulesDetail,
          name: 'RulesDetail',
          meta: {
            keepAlive: false,
          },
        },

        {
          path: '/QuestionPoint',
          component: QuestionPoint,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/question-detail',
          component: QuestionDetail,
          name: 'QuestionDetail',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/Lean',
          component: Lean,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/special-subject',
          component: SpecialSubject,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/risk-subject',
          component: RiskSubject,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/AspSubject',
          component: AspSubject,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/test',
          component: test,
          name: 'test',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/AssignTest',
          component: AssignTest,
          name: 'AssignTest',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/score',
          component: score,
          name: 'score',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/AssignScore',
          component: AssignScore,
          name: 'AssignScore',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/ControlPage',
          component: ControlPage,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/ControlDetial',
          component: ControlDetial,
          name: 'ControlDetial',
          meta: {
            keepAlive: false,
          },
        },
        // 档案管理
        {
          path: '/archives-manage',
          component: ArchivesManage,
          meta: {
            keepAlive: true,
            ifDoFresh: false,
          },
        },
        {
          path: '/audit-childpage',
          component: AuditChildPage,
          name: 'AuditChildPage',
          meta: {
            keepAlive: false,
          },
        },
        // 风控审核
        {
          path: '/risk',
          component: Risk,
          name: 'Risk',
          meta: {
            keepAlive: false,
          },
        },
        {
          path: '/risk-detail',
          component: () => import('../pages/risk/riskDetail.vue'),
          name: 'riskDetail',
          meta: {
            keepAlive: false,
          },
        }
      ],
    },
    {
      path: '/',
      redirect: '/login',
    },
  ],
})

// 点击重复路径报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

router.beforeEach((to, from, next) => {
  if (from.path === '/home' && to.path === '/login') {
    return next('/home')
  }
  if (to.path === '/login') return next()
  //获取token
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})
export default router
