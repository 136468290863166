import { COMPANY_USER_LIST } from './mutation-types'
import { COMPANY_LIST } from './mutation-types'
import { POST_AUD_FUN_STATUS } from './mutation-types'
import { MENU_LIST } from './mutation-types'
import { COMPANY } from './mutation-types'


export default {
  companyUserList({ commit }, data) {
    commit(COMPANY_USER_LIST, { data })
  },
  companyList({ commit }, data) {
    commit(COMPANY_LIST, { data })
  },
  postAudFunStatus({ commit }, data) {
    commit(POST_AUD_FUN_STATUS, { data })
  },
  menuList({ commit }, data) {
    commit(MENU_LIST, { data })
  },
  company({ commit }, data) {
    commit(COMPANY, { data })
  }
} 