import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import pdf from './modules/PDF'
import store from './store'
import { preventReClick,removeAria,dialogDrag } from './modules/directive'

Vue.config.productionTip = false
Vue.use(preventReClick)
Vue.use(removeAria)
Vue.use(dialogDrag)
Vue.use(ElementUI)
Vue.use(pdf)

Vue.config.silent = true
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')


